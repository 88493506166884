<script lang="ts" setup>
import { ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    default: null,
  },
  standalone: {
    type: Boolean,
    default: false,
  },
  // Debounce time in ms
  debounce: {
    type: Number,
    default: 300,
  },
});

const emit = defineEmits(["update:modelValue"]);

// Local state
const amount = ref(null);
const comparisonOperator = ref(">");

// Map between DataTable match modes and our operators
const matchModeToOperator = {
  gt: ">",
  lt: "<",
  equals: "=",
};

const operatorToMatchMode = {
  ">": "gt",
  "<": "lt",
  "=": "equals",
};

// Comparison options
const comparisonOptions = [
  { label: "Supérieur à", value: ">" },
  { label: "Inférieur à", value: "<" },
  { label: "Égal à", value: "=" },
];

// Helper function to build the model value
const buildModelValue = () => {
  if (amount.value === null || amount.value === undefined) {
    return null;
  }

  if (!props.standalone) {
    return amount.value;
  } else {
    return `${comparisonOperator.value}${amount.value}`;
  }
};

// Function to emit the update
const emitUpdate = () => {
  const value = buildModelValue();
  console.log("emit update", value);
  emit("update:modelValue", value);
};

// Create the debounced emit function - commits as user types with debouncing
const debouncedEmit = useDebounceFn(emitUpdate, props.debounce);

// Initialize the component based on the incoming modelValue
const initFromModelValue = () => {
  if (props.modelValue === null || props.modelValue === undefined) {
    amount.value = null;
    comparisonOperator.value = ">";
    return;
  }

  if (!props.standalone) {
    // For DataTable, modelValue is just the number
    amount.value = props.modelValue;
    // Get operator from matchMode
    comparisonOperator.value = matchModeToOperator[props.matchMode] || ">";
  } else {
    // For standalone use, modelValue is a string like ">100"
    const modelStr = String(props.modelValue);
    const firstChar = modelStr.charAt(0);

    if ([">", "<", "="].includes(firstChar)) {
      comparisonOperator.value = firstChar;
      amount.value = parseFloat(modelStr.substring(1)) || null;
    } else {
      // If no operator found, assume it's just a number
      comparisonOperator.value = ">";
      amount.value = parseFloat(modelStr) || null;
    }
  }
};

// Watch for external changes to modelValue
watch(() => props.modelValue, initFromModelValue, { immediate: true });

// Handle amount changes
const onAmountChange = (event) => {
  amount.value = event.value;
  debouncedEmit();
};

// Handle operator changes
const onOperatorChange = (value) => {
  comparisonOperator.value = value;

  // Operator changes emit immediately
  if (amount.value !== null && amount.value !== undefined) {
    emitUpdate(); // Use immediate update for operator changes
  }
};

// Handle key events (for Enter key)
const onKeyDown = (event) => {
  if (event.key === "Enter") {
    emitUpdate(); // Use immediate update for Enter key
  }
};

// Handle blur event
const onBlur = () => {
  if (amount.value !== null && amount.value !== undefined) {
    emitUpdate(); // Use immediate update for blur
  }
};

// Initialize on component creation
initFromModelValue();
</script>

<template>
  <div class="flex gap-2 items-center">
    <Select v-if="standalone" :modelValue="comparisonOperator" @update:modelValue="onOperatorChange"
      :options="comparisonOptions" optionLabel="label" optionValue="value" class="w-1/3" :pt="{
        root: { class: 'flex-shrink-0' },
      }" />

    <InputNumber v-model="amount" @input="onAmountChange" @change="onAmountChange" @keydown="onKeyDown" @blur="onBlur"
      v-key-filter.money :ptOptions="{ mergeSections: false, mergeProps: false }" v-bind="$attrs" class="flex-1"
      placeholder="Montant €" :min="0" />
  </div>
</template>

<style scoped></style>
